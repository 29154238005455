<template>
  <div class="container">
    <!-- 头部 -->
    <header>
      <div class="top"> {{ $t('property').title }}</div>
    </header>
    <!-- 用户钱包 -->
    <div class="u_adress">
      <div class="user">
        <img v-if="userInfo.Head" :src="userInfo.Head" width="22" />
        <img v-else src="@/assets/img/heard.png" width="22" />
        <span>ID:{{ userInfo.Id }}</span>
      </div>
      <!--  钱包地址-->
      <span @click="$router.push({ name: 'WithAddress' })">{{ $t('property').walletAddress }}：{{
        maskString3_3(userInfo.TRC20Address) }}<van-icon name="arrow" color="#999999" size="15" /></span>
    </div>
    <!-- 资产 -->
    <div class="u_banlce">
      <img src="@/assets/img/property_bg.png" />
      <div class="title">
        <!--  总资产-->
        <div class="tt"><span>{{ $t('property').AllAmount }}(USD)</span> <van-icon name="eye-o" size="18"
            color="#999999" v-if="!showAllAmount" @click="changeShowAll" /><van-icon name="closed-eye" size="18"
            color="#999999" v-if="showAllAmount" @click="changeShowAll" /></div>
        <!--  可用余额-->
        <div class="tt"><span>{{ $t('property').CanAmount }}(USD)</span> <van-icon name="eye-o" size="18"
            color="#999999" v-if="!showCanAmount" @click="changeShowCan" /><van-icon name="closed-eye" size="18"
            color="#999999" v-if="showCanAmount" @click="changeShowCan" /></div>
      </div>
      <div class="num">{{ AllAmount }} <span>{{ CanAmount }}</span></div>
    </div>
    <!-- 充币提币 -->
    <div class="btns">
      <div class="b1" @click="$router.push({ name: 'Recharge' })">{{ $t('recharge').title }}</div>
      <div class="b2" @click="$router.push({ name: 'With' })">{{ $t('with').title }}</div>
    </div>
    <!-- tab切换 -->
    <van-tabs v-model="active" line-width="28" line-height="3" background="#F6F6F6" color="#C92B2E"
      title-active-color="#C92B2E" title-inactive-color="#333333">
      <!-- 资金账户 -->
      <van-tab :title="$t('property').funtAccount">
        <div class="fundInfo">
          <div class="item">
            <div class="tops">
              <div class="left">
                <img src="@/assets/img/USDT.png" width="48" />
                <div class="lle">
                  <div class="name">USDT</div>
                  <div class="price">{{ $t('property').Balance }}：{{ userInfo.Balance }}</div>
                </div>
              </div>
              <div class="right">
                <div class="txt">{{ $t('property').convert }}(USD)</div>
                <div class="num">{{ userInfo.Balance }}</div>
              </div>
            </div>
            <div class="bottom" @click="$router.push({ name: 'Exchange', query: { code: 'USDT' } })">
              <img src="@/assets/img/p_exchange.png" width="14" /><span>{{ $t('exchange').title }}</span>
            </div>
          </div>
          <div class="item" v-for="(item, index) in LegalWalletList" :key="`legals${index}`">
            <div class="tops">
              <div class="left">
                <img :src="item.Logo" width="48" height="48" />
                <div class="lle">
                  <div class="name">{{ item.Currency }}</div>
                  <div class="price">{{ $t('property').Balance }}：{{ item.Balance }}</div>
                </div>
              </div>
              <div class="right">
                <div class="txt">{{ $t('property').convert }}(USD)</div>
                <div class="num">{{ item.USDT }}</div>
              </div>
            </div>
            <div class="bottom" @click="$router.push({ name: 'Exchange', query: { code: item.Currency } })">
              <img src="@/assets/img/p_exchange.png" width="14" /><span>{{ $t('exchange').title }}</span>
            </div>
          </div>
        </div>
      </van-tab>
      <!-- 合约持仓 -->
      <van-tab :title="$t('property').contractposition">
        <div class="contractInfo">
          <img src="@/assets/img/nodata.png" width="64" />
        </div>
      </van-tab>
      <!-- 托管项目 -->
      <van-tab :title="$t('property').tsProject">
        <div class="trustShipInfo">
          <div class="total">{{ $t('property').tsAmount }}：{{ TS_Amount }}</div>
          <div class="title">
            <div class="name">{{ $t('property').tsName }}</div>
            <span>{{ $t('property').buyTime }}</span>
            <span>{{ $t('property').endTime }}</span>
            <div class="btn">{{ $t('property').operate }}</div>
          </div>
          <van-list v-model="TS_loading" :finished="TS_finished" :loading-text="$t('public').loadingText"
            @load="GetTsOrderLog" :immediate-check="false">
            <div class="item" v-for="(item, index) in TS_List" :key="`tslogs${index}`">
              <div class="name">{{ item.ProductName }}</div>
              <div class="time">{{ item.CreateTime | getLocalDate }}</div>
              <div class="time">{{ item.EndTime | getLocalDate }}</div>
              <!-- 中止托管 -->
              <div class="btn" v-if="item.Status == 'Wait'"
                @click="$router.push({ name: 'TsOrderInfo', query: { Id: item.Id } })">{{ $t('property').stopTs }}</div>
              <div class="msg" v-else @click="$router.push({ name: 'TsOrderInfo', query: { Id: item.Id } })">{{
                $t('property').toSee }}</div>
            </div>
          </van-list>
        </div>
      </van-tab>
      <!-- 我的团队 -->
      <van-tab :title="$t('property').myTeam">
        <div class="teamInfo">
          <!-- 用户 -->
          <div class="uu">
            <img v-if="userInfo.Head" :src="userInfo.Head" style="border-radius: 50%;" width="51" />
            <img v-else src="@/assets/img/heard.png" style="border-radius: 50%;" width="51" />
            <div class="tit" v-if="userInfo.Id">
              <div class="nan">
                <div class="ids">ID：{{ userInfo.Id }}</div>
                <img :src="require(`@/assets/img/${userInfo.Grade}.png`)" style="margin-left: 10px;" width="56" />
                <!-- 申请成为经纪人 -->
                <div class="btn"
                  v-if="userInfo.Grade == 'Mv3' && TradeConfig.Tv_Enable && userInfo.TvAuditStatus != 'Wait'"
                  @click="showApply = true">{{
                    $t('property').toTeam }}
                </div>
                <div class="btn_no" v-if="userInfo.TvAuditStatus == 'Wait'" @click="showApply = true">{{
                  $t('property').tvApplying }}</div>
                <!-- 等级奖励机制 -->
                <img src="@/assets/img/question.png" style="margin-left: 10px;" width="15" @click="showGrade = true" />
              </div>
              <div class="tra">{{ $t('public').broker }}ID：{{ userInfo.ParentId }}</div>
              <div class="prog" v-if="userInfo.Grade.includes('Mv')">
                <img :src="require(`@/assets/img/p_${userInfo.Grade}.png`)" width="16" />
                <van-progress :percentage="TS_TeamInfo.UpPercent" :show-pivot="false"
                  style="width: 120px;margin-top: 3px" track-color="#FFEDE0"
                  color="linear-gradient( 312deg, #FF6E00 0%, #FFC080 100%)" />
              </div>
              <div class="prog" v-if="userInfo.Grade.includes('Tv')">
                <img :src="require(`@/assets/img/p_${userInfo.Grade}.png`)" width="16" />
                <van-progress :percentage="TS_TeamInfo.UpPercent" :show-pivot="false"
                  style="width: 120px;margin-top: 3px" track-color="#F6ECFF"
                  color="linear-gradient( 312deg, #8000FF 0%, #D22AFC 100%)" />
              </div>
            </div>
          </div>
          <!-- 团队统计 -->
          <div class="numAll">{{ $t('property').teamNum }}：{{ TS_TeamInfo.TeamNum }}
            <span><van-field v-model="Depth" type="digit" :placeholder="$t('property').depthMsg" @blur="GetDepthSearch"
                clearable @clear="GetDepthSearch"></van-field></span>
          </div>
          <!-- 数据 -->
          <div class="numInfo">
            <div class="num"><span>{{ TS_TeamInfo.Amount }}</span><span>{{ TS_TeamInfo.Profit
                }}</span><span>{{ TS_TeamInfo.Difference }}</span><span>{{
                  TS_TeamInfo.AlreadyProfit }}</span></div>
            <div class="msg"><span>{{ $t('property').tsAmountTotal }}</span><span>{{ $t('property').ProfitTotal
                }}</span><span>{{ $t('property').Difference }}</span><span>{{ $t('property').AlreadyProfit }}</span>
            </div>
          </div>
          <!-- 明细 -->
          <div class="title">
            <div class="name">ID</div>
            <div class="dep">{{ $t('property').depth }}</div>
            <span>{{ $t('property').tsToName }}</span>
            <span>{{ $t('property').profit }}</span>
            <span>{{ $t('property').alePorfit }}</span>
            <div class="btn">{{ $t('property').operate }}</div>
          </div>
          <van-list v-model="Team_loading" :finished="Team_finished" :loading-text="$t('public').loadingText"
            @load="GetTsTeamList" :immediate-check="false">
            <div class="item" v-for="(item, index) in Team_List" :key="`tsteams${index}`">
              <div class="name">{{ item.Id }}</div>
              <div class="dep">{{ item.Depth }}</div>
              <span>{{ item.Amount }}</span>
              <span>{{ item.Profit }}</span>
              <span>{{ item.AlreadyProfit }}</span>
              <div class="btn" @click="toShowChildLog(item.Id)">{{ $t('property').toSee }}</div>
            </div>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>
    <!-- 申请经纪人 -->
    <van-popup v-model="showApply" closeable round :style="{ width: '86%', borderRadius: '10px' }">
      <div class="popInfo">
        <div class="title"> {{ $t('property').apply }}</div>
        <div class="info">
          <div class="address">{{ $t('property').applyMsg }}</div>
          <div class="btns">
            <div class="btn" @click="showApply = false">{{ $t('public').cancel }}</div>
            <div class="btn2" @click="ApplyTeamV">{{ $t('public').confirm }}</div>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 中止托管 -->
    <van-popup v-model="showStop" closeable round :style="{ width: '86%', borderRadius: '10px' }">
      <div class="popInfo">
        <div class="title"> {{ $t('property').tips }}</div>
        <div class="info">
          <div class="address">{{ $t('property').stopMsg }}</div>
          <div class="btns">
            <div class="btn">{{ $t('public').sure }}</div>
            <div class="btn2" @click="showStop = false">{{ $t('public').cancel }}</div>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 等级奖励机制 -->
    <van-popup v-model="showGrade" closeable round :style="{ width: '94%', borderRadius: '10px' }">
      <div class="popInfo">
        <div class="title"> {{ $t('property').gradeMsg1 }}</div>
        <div class="gradeInfo">
          <div class="tit">{{ $t('property').gradeMsg2 }}</div>
          <div class="item">
            <div class="rod"></div>
            <img src="@/assets/img/p_Mv1.png" width="18" />
            <div class="txt">{{ $t('property').gradeMsg3 }}{{ TradeConfig.Mv1_1Rate }}%</div>
          </div>
          <div class="item">
            <div class="rod"></div>
            <img src="@/assets/img/p_Mv2.png" width="18" />
            <div class="txt">{{ $t('property').gradeMsg3 }}{{ TradeConfig.Mv2_1Rate }}%{{ $t('property').gradeMsg4
              }}{{ TradeConfig.Mv2_2Rate }}%</div>
          </div>
          <div class="item">
            <div class="rod"></div>
            <img src="@/assets/img/p_Mv3.png" width="18" />
            <div class="txt">{{ $t('property').gradeMsg3 }}{{ TradeConfig.Mv3_1Rate }}%{{ $t('property').gradeMsg4
              }}{{ TradeConfig.Mv3_2Rate }}%{{ $t('property').gradeMsg5 }}{{ TradeConfig.Mv3_3Rate }}%</div>
          </div>
          <div class="tips">{{ $t('property').gradeMsg6 }}</div>
          <div class="item">
            <div class="rod1"></div>
            <img src="@/assets/img/p_Tv1.png" width="18" />
            <div class="txt">{{ $t('public').broker }} {{ $t('property').gradeMsg7 }}{{ TradeConfig.Tv1_Rate }}%</div>
          </div>
          <div class="item">
            <div class="rod1"></div>
            <img src="@/assets/img/p_Tv2.png" width="18" />
            <div class="txt">{{ $t('public').broker }} {{ $t('property').gradeMsg7 }}{{ TradeConfig.Tv2_Rate }}%</div>
          </div>
          <div class="item">
            <div class="rod1"></div>
            <img src="@/assets/img/p_Tv3.png" width="18" />
            <div class="txt">{{ $t('public').broker }} {{ $t('property').gradeMsg7 }}{{ TradeConfig.Tv3_Rate }}%</div>
          </div>
          <div class="item">
            <div class="rod1"></div>
            <img src="@/assets/img/p_Tv4.png" width="18" />
            <div class="txt">{{ $t('public').broker }} {{ $t('property').gradeMsg7 }}{{ TradeConfig.Tv4_Rate }}%</div>
          </div>
          <div class="item">
            <div class="rod1"></div>
            <img src="@/assets/img/p_Tv5.png" width="18" />
            <div class="txt">{{ $t('public').broker }} {{ $t('property').gradeMsg7 }}{{ TradeConfig.Tv5_Rate }}%</div>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 项目明细 -->
    <van-popup v-model="showOrderList" closeable round :style="{ width: '94%', height: '450px', borderRadius: '10px' }">
      <div class="popInfo">
        <div class="title"> {{ $t('property').projectInfo }}</div>
        <div class="orderListInfo">
          <div class="nan">ID：{{ childTs_Id }}</div>
          <van-list v-model="childTs_loading" :finished="childTs_finished" :loading-text="$t('public').loadingText"
            @load="GetChildTsOrderLog" :immediate-check="false">
            <div class="item" v-for="(item, index) in childTs_List" :key="`childtss${index}`">
              <div class="name">{{ $t('property').tsName }}：{{ item.ProductName }} <span
                  :class="item.Status == 'Wait' ? 'green' : item.Status == 'Cancel' ? 'red' : ''">{{ item.Status ==
                    'Wait' ?
                    $t('property').waiting : item.Status
                      == 'Complete' ? $t('property').completed : item.Status == 'Cancel' ? $t('property').canceled : ''
                  }}</span></div>
              <div class="txt">{{ $t('property').tsAmount }}：{{ item.Amount }} USDT</div>
              <div class="txt">{{ $t('property').buyTime }}： {{ item.CreateTime | getLocalTime }}</div>
              <div class="txt">{{ $t('property').endTime }}： {{ item.EndTime | getLocalTime }}</div>
              <div class="txt">{{ $t('property').rate }}： {{ item.Rate }}%</div>
              <div class="txt">{{ $t('property').endProfit }}：{{ item.Profit }} USDT</div>
            </div>
          </van-list>
        </div>
      </div>
    </van-popup>
    <TabBar :active="'Property'" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import systemApi from '@/api/system'
import userApi from '@/api/user'
import orderApi from '@/api/order'
import TabBar from '@/components/tabBar'
import PublicHeader from '@/components/publicHeader'
import { Token } from '@/utils/mm'

export default {
  components: {
    TabBar,
    PublicHeader
  },
  data() {
    return {
      userInfo: {},
      showAllAmount: false,
      AllAmount: '********',
      showCanAmount: false,
      CanAmount: '********',
      LegalWalletList: [],
      // 托管记录
      TS_Amount: '',
      TS_List: [],
      TS_loading: false,
      TS_finished: false,
      TS_parms: {
        page: 1,
        size: 10,
      },
      // 团队记录
      Depth: '',
      TS_TeamInfo: {},
      Team_List: [],
      Team_loading: false,
      Team_finished: false,
      Team_parms: {
        page: 1,
        size: 10,
      },
      // 成员托管记录
      isRun: false,
      childTs_Id: '',
      childTs_List: [],
      childTs_loading: false,
      childTs_finished: false,
      childTs_parms: {
        page: 1,
        size: 10,
      },
      active: 0,
      showApply: false,
      showStop: false,
      showGrade: false,
      showOrderList: false,
      TradeConfig: {},
    }
  },
  computed: {
    ...mapGetters(['basicConfig', 'connection', 'baseMsg']),
  },
  mounted() {
    this.getAccountBase()
    this.GetLegalWallets()
    this.GetTsOrderAmount()
    this.GetTsTeamNum()
    this.GetTsOrderLog()
    this.GetTsTeamList()
    this.getClientConfig()
  },
  methods: {
    // 获取用户信息
    async getAccountBase() {
      const res = await userApi.getAccountBase()
      this.userInfo = res.Data
    },
    //获取客户端配置
    async getClientConfig() {
      const res = await systemApi.getClientConfig()
      this.TradeConfig = res.Data.TradeModel
    },
    // 申请成为经纪人
    async ApplyTeamV() {
      await userApi.ApplyTeamV()
      this.showApply = false
      this.getAccountBase()
      this.GetTsTeamNum()
      this.$toast(this.$t('property').applySuccess)
    },
    // 切换展示总资产
    changeShowAll() {
      this.showAllAmount = !this.showAllAmount
      if (this.showAllAmount)
        this.AllAmount = this.userInfo.Balance + this.userInfo.Freeze
      else
        this.AllAmount = '********'
    },
    // 切换展示可用资产
    changeShowCan() {
      this.showCanAmount = !this.showCanAmount
      if (this.showCanAmount)
        this.CanAmount = this.userInfo.Balance
      else
        this.CanAmount = '********'
    },
    // 前3后3加密
    maskString3_3(str) {
      if (str)
        return str.replace(/^(.{3}).+?(.{3})$/, '$1********$2');
    },
    // 获取资金账户列表
    async GetLegalWallets() {
      const res = await userApi.GetLegalWallets()
      this.LegalWalletList = res.Data
    },
    // 获取托管总额
    async GetTsOrderAmount() {
      this.TS_Amount = await orderApi.GetTsOrderAmount()
    },
    //层级筛选
    GetDepthSearch() {
      this.GetTsTeamNum()
      this.Team_parms.Depth = this.Depth
      this.Team_parms.page = 1
      this.Team_List = []
      this.GetTsTeamList()
    },
    // 获取团队数据
    async GetTsTeamNum() {
      var res = await orderApi.GetTsTeamNum({ Depth: this.Depth })
      this.TS_TeamInfo = res.Data
    },
    // 托管记录
    async GetTsOrderLog() {
      const res = await orderApi.GetTsOrderLog(this.TS_parms)
      this.TS_List.push(...res.Data);
      this.TS_parms.page++
      // 加载状态结束
      this.TS_loading = false;
      // 数据全部加载完成
      if (this.TS_List.length >= res.TotalCount) {
        this.TS_finished = true;
      }
    },
    // 获取团队记录
    async GetTsTeamList() {
      const res = await orderApi.GetTsTeamList(this.Team_parms)
      this.Team_List.push(...res.Data);
      this.Team_parms.page++
      // 加载状态结束
      this.Team_loading = false;
      // 数据全部加载完成
      if (this.Team_List.length >= res.TotalCount) {
        this.Team_finished = true;
      }
    },
    toShowChildLog(Id) {
      this.childTs_Id = Id
      this.showOrderList = true
      this.childTs_List = []
      this.childTs_parms.page = 1
      this.childTs_finished = false;
      this.GetChildTsOrderLog()
    },
    // 成员托管记录
    async GetChildTsOrderLog() {
      if (this.isRun) return
      this.isRun = true
      this.childTs_parms.Id = this.childTs_Id
      const res = await orderApi.GetTsOrderLog(this.childTs_parms)
      this.childTs_List.push(...res.Data);
      this.isRun = false
      this.childTs_parms.page++
      // 加载状态结束
      this.childTs_loading = false;
      // 数据全部加载完成
      if (this.childTs_List.length >= res.TotalCount) {
        this.childTs_finished = true;
      }
    },

  }
}
</script>

<style lang="less" scoped>
.red {
  color: #C92B2E !important;
}

.green {
  color: #02B270 !important;
}

.container {
  min-height: 100vh;
  font-size: 14px;
  padding-bottom: 70px;
  background: #F6F6F6;

  ::v-deep .van-tab {
    font-size: 15px;
  }

  ::v-deep .van-tab--active {
    font-weight: bold;
  }

  ::v-deep .van-popup__close-icon {
    color: #000000;
  }

  header {
    width: 100%;
    height: 120px;
    background-image: url(../../assets/img/heard_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .top {
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 800;
      font-size: 17px;
      color: #333333;
    }
  }

  .u_adress {
    margin: -60px 13px 0 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .user {
      display: flex;
      align-items: center;

      img {
        margin-right: 5px;
        border-radius: 50%;
      }
    }

    span {
      font-size: 14px;
      color: #999999;
    }
  }

  .u_banlce {
    margin: 15px 13px 0 13px;
    height: 95px;
    background: #282B2E;
    border-radius: 10px;

    .title {
      margin: -80px 0 0 0;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tt {
        display: flex;
        align-items: center;

      }

      span {
        margin-right: 5px;
        font-size: 14px;
        color: #999999;
      }

    }

    .num {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      font-size: 18px;
      color: #FFFFFF;
    }
  }

  .btns {
    margin: 15px 13px 8px 13px;
    display: flex;
    justify-content: space-between;

    .b1 {
      width: 50%;
      height: 35px;
      background: #F7380D;
      box-shadow: inset 0 0 6px 1px #FF7E61;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #FFFFFF;
    }

    .b2 {
      margin-left: 10px;
      width: 50%;
      height: 35px;
      background: #1B76DF;
      box-shadow: inset 0 0 6px 1px #84BDFF;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #FFFFFF;
    }
  }

  .fundInfo {
    margin-top: 10px;
    background: #FFFFFF;
    border-radius: 20px 20px 0 0;
    padding: 15px 13px;

    .item {
      margin-bottom: 7px;
      background: #F9F9F9;
      border-radius: 5px;

      .tops {
        padding: 7px;
        border-bottom: 1px solid #E0E0E0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          display: flex;
          align-items: center;

          img {
            border-radius: 50%;
          }

          .lle {
            margin-left: 10px;

            .name {
              font-weight: bold;
              font-size: 16px;
              color: #333333;
            }

            .price {
              margin-top: 10px;
              font-size: 13px;
              color: #333333;
            }
          }
        }

        .right {
          text-align: right;

          .txt {
            font-size: 13px;
            color: #999999;
          }

          .num {
            margin-top: 10px;
            font-size: 13px;
            color: #333333;
          }
        }

      }

      .bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;

        span {
          margin-left: 5px;
          font-size: 13px;
          color: #333333;
        }
      }
    }
  }

  .contractInfo {
    padding: 50px 0;
    text-align: center;
  }

  .trustShipInfo {
    margin-top: 10px;
    background: #FFFFFF;
    border-radius: 20px 20px 0 0;
    padding: 15px 13px;

    .total {
      height: 38px;
      background: #F9F9F9;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 16px;
      color: #333333;
    }

    .title {
      margin: 15px 0 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #999999;

      .name {
        text-align: center;
        width: 30%;
        border-right: 1px solid #999999;
      }

      span {
        text-align: center;
        width: 27%;
        border-right: 1px solid #999999;
      }

      .btn {
        text-align: center;
        width: 20%;
      }
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 0;
      border-bottom: 1px solid #E3E3E3;

      .name {
        width: 30%;
        font-weight: bold;
        font-size: 16px;
        color: #333333;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .time {
        width: 25%;
        font-size: 13px;
        color: #999999;
        text-align: center;
      }

      .btn {
        width: 20%;
        height: 26px;
        background: rgba(255, 255, 255, 0);
        border-radius: 3px;
        border: 1px solid #5C5C5C;
        font-size: 13px;
        color: #5C5C5C;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .msg {
        width: 20%;
        height: 26px;
        font-size: 13px;
        color: #C92B2E;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .teamInfo {
    margin-top: 10px;
    padding-bottom: 13px;
    background: #FFFFFF;
    border-radius: 20px 20px 0 0;

    .uu {
      display: flex;
      align-items: center;
      padding: 15px 13px;
      border-bottom: 1px solid #E3E3E3;

      .tit {
        margin-left: 10px;

        .nan {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .ids {
            margin-top: 2px;
            font-weight: bold;
            font-size: 16px;
            color: #333333;
          }

          .btn {
            margin-left: 10px;
            width: 79px;
            height: 24px;
            background: #C92B2E;
            border-radius: 4px;
            border: 1px solid #B80D10;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            color: #FFFFFF;
          }

          .btn_no {
            margin-left: 10px;
            padding: 0 10px;
            height: 24px;
            background: #969595;
            border-radius: 4px;
            border: 1px solid #d6d6d6;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            color: #FFFFFF;
          }
        }

        .tra {
          margin-top: 5px;
          font-size: 12px;
          color: #999999;
          text-transform: none;
        }

        .prog {
          margin-top: 5px;
          display: flex;
          align-items: center;
        }
      }
    }

    .numAll {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 13px 13px 0 13px;
      font-weight: bold;
      font-size: 15px;
      color: #333333;

      span {
        font-weight: normal;
        width: 35%;
      }

      .van-cell {
        height: 35px;
        border: 1px solid #DDDDDD;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
      }
    }

    .numInfo {
      margin: 10px 13px 0 13px;
      padding: 10px 0;
      background: #F7F7F7;

      .num {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        font-size: 15px;
        color: #333333;

        span {
          width: 25%;
          text-align: center;
          word-break: break-all;
        }
      }

      .msg {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: #999999;

        span {
          width: 25%;
          text-align: center;
        }
      }
    }

    .title {
      margin: 15px 13px 10px 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #999999;
      text-align: center;

      .name {
        width: 25%;
        border-right: 1px solid #999999;
        text-align: center;
      }

      .dep {
        width: 15%;
        border-right: 1px solid #999999;
        text-align: center;
      }

      span {
        width: 21%;
        border-right: 1px solid #999999;
        text-align: center;
      }

      .btn {
        width: 15%;
        text-align: center;
      }
    }

    .item {
      margin: 0 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 13px 0;
      border-bottom: 1px solid #E3E3E3;
      font-size: 13px;
      color: #333333;
      text-align: center;

      .name {
        width: 25%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }

      .dep {
        width: 15%;
        text-align: center;
      }

      span {
        width: 21%;
        text-align: center;
      }

      .btn {
        width: 15%;
        color: #C92B2E;
        text-align: center;
      }
    }

  }

  .popInfo {
    .title {
      padding: 20px 0;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      color: #333333;
      border-bottom: 1px solid #DCDCDC;
    }

    .info {
      padding: 15px 13px 20px 13px;
      text-align: center;

      .address {
        margin: 40px 0;
        font-weight: bold;
        font-size: 16px;
        color: #333333;
        line-height: 25px;
        overflow-wrap: break-word;
      }

      .tip {
        padding: 10px 0 0 0;
        font-size: 14px;
        color: #FF9B37;
      }

      .btns {
        margin: 20px 13px 0 13px;
        display: flex;
        justify-content: space-between;

        .btn {
          width: 119px;
          height: 40px;
          background: rgba(201, 43, 46, 0);
          border: 1px solid #C92B2E;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          color: #C92B2E;
        }

        .btn2 {
          width: 119px;
          height: 40px;
          background: #C92B2E;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          color: #fff;
        }
      }
    }

    .gradeInfo {
      padding: 20px 25px;

      .tit {
        font-weight: bold;
        font-size: 13px;
        color: #333333;

      }

      .item {
        margin-top: 17px;
        display: flex;
        align-items: center;

        .rod {
          margin-right: 5px;
          width: 3px;
          height: 3px;
          background: #FF8427;
          border-radius: 1px;
        }

        .rod1 {
          margin-right: 5px;
          width: 3px;
          height: 3px;
          background: #8C06FF;
          border-radius: 1px;
        }

        .txt {
          margin: 5px 0 0 10px;
          font-size: 13px;
          color: #666666;
        }
      }

      .tips {
        margin: 7px 0 0 35px;
        font-weight: bold;
        font-size: 13px;
        color: #FF8427;
      }

    }

    .orderListInfo {
      padding: 15px;

      .nan {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 13px;
        color: #333333;
      }

      .item {
        margin-top: 10px;
        padding-bottom: 10px;
        background: #FFFFFF;
        border-radius: 2px;
        border: 1px solid #DCDCDC;

        .name {
          padding: 0 10px;
          height: 30px;
          display: flex;
          align-items: center;
          background: #F8F8F8;
          font-weight: bold;
          font-size: 13px;
          color: #333333;
          justify-content: space-between;

          span {
            font-size: 13px;
            color: #FF8427;
          }
        }

        .txt {
          padding-left: 10px;
          margin-top: 10px;
          font-size: 13px;
          color: #333333;
        }
      }
    }
  }

}
</style>
